

































import { Component, mixins, Prop } from 'nuxt-property-decorator';
import BreakpointMixin from '../../mixins/BreakpointMixin';
import { BlokContainerBox } from '~/types';

@Component
export default class ContainerBox extends mixins(BreakpointMixin) {
  @Prop() blok!: BlokContainerBox;
  // -> GSAP animation
  $refs: { box: HTMLElement };
  animate (): void {
    this.$gsap.to(this.$refs.box, {
      scrollTrigger: {
        trigger: () => this.$refs.box,
        start: this.breakpointUp('md') ? 'top bottom-=150px' : 'top bottom'
      },
      ease: 'power1.out',
      duration: 1.5,
      y: 0,
      opacity: 1
    });
  }
  mounted () {
    if (!process.browser) { return; }
    const folder = this.$route.params.folder;
    if (folder === 'blog' || folder === 'projekte' || folder === 'metamotivation') { return; }
    this.$gsap.set(this.$refs.box, {
      y: this.breakpointUp('md') ? 200 : 50,
      opacity: 0
    });
    this.animate();
  }
}
